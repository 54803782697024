import './index.css'
import * as React from 'react'

import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'

import LiveCasino from '../LiveCasino'
import Partners from '../Partners'
import DepositMethods from '../DepositMethods'
import Sponsors from '../Sponsors'
import FreeSpins from '../FreeSpins'

function Footer() {

  return (
    <footer className="Footer-main">
      <FreeSpins />
      <LiveCasino />
      <Partners />
      <DepositMethods />
      <Sponsors />
      <Stack direction="column" spacing={0}>
        <Stack className="Footer-menu-row" direction="row" spacing={8}>
          <Stack className="Footer-menu-column" direction="column" spacing={3}>
            <Link href="/about">About</Link>
            <Link href="/advertise">Advertise</Link>
            <Link href="/privacy/cookie-settings">Cookie Settings</Link>
            <Link href="/privacy">Privacy Settings</Link>
          </Stack>
        </Stack>
        <p className="Footer-menu-copyright">Copyright 2024 M88 TV. All rights reserved</p>
      </Stack>
    </footer>
  )

}

export default Footer
