export function formatAMPM(date) {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
}

export function isToday({startTime}) {
  const start = new Date(startTime.getTime())
  const date = new Date()
  const today = date.setHours(0,0,0,0)
    const startDay = start.setHours(0,0,0,0)
    if (today === startDay) {
      return true
    }
    
    return false
}

export function gameTime(game, showTime = false) {
    const startTime = new Date(game.startTime)
    // console.log('firstGameStartTime', game.startTime, startTime, game)
    const amPmTime = formatAMPM(startTime)
    // console.log('theStartTime', game.startTime)
    const date = new Date()
    const now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
    const now = new Date(now_utc)
    // console.log('current utc', date.toISOString(), now_utc)
    const endTime = new Date(game.endTime)

    // console.log('test date', startTime > endTime, endTime > startTime)
    
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'December']
    // Is this game currently live?
    // console.log(
    //   { startTime: startTime.getTime() },
    //   { endTime: endTime.getTime() }, 
    //   { now: now.getTime() }, 
    //   now.getTime() > startTime.getTime() && now.getTime() < endTime.getTime()
    // )
   const fullDateString = `${days[startTime.getDay()]}, ${months[startTime.getMonth()]} ${startTime.getDate()} @${amPmTime}`
    if (showTime) {
      // console.log('gameTimeFull', {startTime})
        return `${isToday({startTime: startTime}) ? 'Today' : days[startTime.getDay()]}, ${months[startTime.getMonth()]} ${startTime.getDate()} @ ${formatAMPM(startTime)}`
    }

    // Is it going on now?
    //console.log('the times', now.getTime(), now_utc, startTime.getTime(), endTime.getTime())
    if (now > startTime) {
      // console.log('live only', startTime, now, now.getTime() > startTime.getTime() && now.getTime() < endTime.getTime())
    }
    if (now.getTime() > startTime.getTime() && now.getTime() < endTime.getTime()) {
      return `LIVE`
    } 
    
    // Has it ended?
    if (now.getTime() > endTime.getTime()) {
      return 'ENDED'
    }
    //console.log('beforeChange', startTime)

    // if this is today
    if (isToday({startTime}) ) {
      return `Today, ${amPmTime}`
    } 
    
    return fullDateString;
  }