import './index.css'

import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Footer from '../Footer'
import Header from '../Header'
import BodyGamesPage from '../BodyGamesPage'

function GamesPage() {

  const { pageName } = useParams()
  const gameTime = pageName === 'live-games' ? 'live' : 'upcoming'
  return (
    <Box className="home-box" sx={{ flexGrow: 1 }}>
      <Header />
        <BodyGamesPage gameTime={gameTime} />
      <Footer />
    </Box>
  )

}

export default GamesPage
