import { Box } from '@mui/material'
import DepositMethods from '../DepositMethods'
import LiveCasino from '../LiveCasino'
import Partners from '../Partners'
import Sponsors from '../Sponsors'
import './index.css'
import Header from '../Header'

function AboutPage() {
  return (
    <Box className="home-box" sx={{ flexGrow: 1 }}>
      <Header />
      <LiveCasino />
      <Partners />
      <DepositMethods />
      <Sponsors />
    </Box>
  )
}

export default AboutPage