import './video.css'
import React from 'react';
const Hls = require('hls.js')

export const VideoPlayer = (props) => {
  const { src } = props;
  const hlsConfig = {
    appendErrorMaxRetry: 10 
  };

  React.useEffect(() => {
    const video = document.getElementById('stream-player');
    console.log('video', video);
    if (!Hls.isSupported()) {
      console.error('hls is not supported.');
      video.src = src;
      return;
    }

    var hls = new Hls(hlsConfig);
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
     video.play();
    });
    hls.loadSource(src);
    hls.attachMedia(video);
  }, [src]);

  return (
    <div className="streamVideoPlayer">
    <video
      id="stream-player"
      className="streamPlayer"
      poster={ process.env.PUBLIC_URL + '/player-placeholder.png' }
      data-setup="{&quot;fluid&quot;: true}"
      autoplay muted playsinline
      controls
    >
      <p className="vjs-no-js">To view this video please enable JavaScript</p>
    </video>
  </div>
  );
}

export default VideoPlayer;