import './index.css'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { gameTime } from '../../utils'

function GameTile(props) {
  const {
    game,
    teamA,
    teamB,
    type,
    // league
  } = props

  const gameTimeString = gameTime(game)

  const styles = {
      listItemGameBox: {
          background: "none"
      },
      teamABadge: {
          width: "50px",
          height: "50px",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
      },
      teamBBadge: {
          width: "50px",
          height: "50px",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
      }
  }

  // If game is Upcoming
  if (type !== 'live') {
    console.log('displaying', gameTimeString)
    return (
      <Box style={styles.listGameBox}>
        <Link 
          style={{
            textDecoration: 'none'
          }}
          href={`/game/${game.id}`}
        >
          <Box className="game-tile-graphics upcoming-tile"
            style={{
              backgroundColor: "#000000",
              backgroundImage: "url('/img/soccer_stadium.jpeg')"
            }}
          >
            <div className="game-tile-teams">
              <img 
                className="teamBadge teamBadge-A"
                alt=""
                src={( teamA.iconURL !== "" ) ? teamA.iconURL : "/img/badges/default-shield-team-a.png"}
              />
              <img
                className="teamBadge teamBadge-B"
                alt=""
                src={( teamB.iconURL !== "" ) ? teamB.iconURL : "/img/badges/default-shield-team-b.png"}
              />
            
            </div>
            <div className="game-tile-typography">
              <div className="game-tile-typography-labels">
                <span 
                  className="game-tile-typography-label upcoming-label"
                >UPCOMING</span>
              </div>
              <div className="game-tile-typography-title">
                {`${teamA.name} vs. ${teamB.name}`}
              </div>
              <div className="game-tile-time-upcoming">
                <span>
                  { gameTime(game, true) }
                </span>
              </div>
            </div> 
          </Box>
        </Link>
      </Box>
    )
  } else { 
    return (
      <Box style={styles.listItemGameBox}>
        <Link 
          style={{
            textDecoration: 'none'
          }}
          href={`/game/${game.id}`}
        >
          <Box className="game-tile-graphics"
              style={{
                  backgroundColor: "#013A6B",
                  backgroundImage: `-webkit-linear-gradient(-30deg, ${teamA.color ? teamA.color : '#ffffff'} 50%, ${teamB.color ? teamB.color : '#ffffff'} 50%)`
              }}
              >
              <div className="game-tile-teamA game-tile-team">
                  <img 
                      className="teamBadge teamBadge-A"
                      alt=""
                      src={( teamA.iconURL !== "" ) ? teamA.iconURL.replace(/^.*\/\/[^\/]+/, '') : "/img/badges/default-shield-team-a.png"}
                  />
              </div>
              <div className="game-tile-teamB game-tile-team">
                  <img
                      className="teamBadge teamBadge-B"
                      alt=""
                      src={( teamB.iconURL !== "" ) ? teamB.iconURL.replace(/^.*\/\/[^\/]+/, '') : "/img/badges/default-shield-team-b.png"}
                  />
              </div>
              <div className="game-tile-time">
                <Typography>
                  {
                    (gameTimeString === "LIVE") && (
                      <span className="liveBullet">&bull;</span>
                    )
                  }
                  {gameTimeString}
                </Typography>
              </div>
          </Box>
          <Box id="game-tile-typography">
              <Typography>{teamA.name} vs {teamB.name}</Typography>
          </Box>
        </Link>
      </Box>
    )
  }
}

export default GameTile
