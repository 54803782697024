const defaultHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}

class HTTP {

  static async get(endpoint, headers = {}) {
    const res = await fetch(endpoint, {
      headers,
      method: 'GET'
    })
    if (res.status >= 500) {
      throw new Error(res.statusText)
    }
    return res
  }

  static async delete(endpoint, headers = {}) {
    const res = await fetch(endpoint, {
      headers,
      method: 'DELETE'
    })
    if (res.status >= 500) {
      throw new Error(res.statusText)
    }
    return res
  }

  static async patch(endpoint, data, headers = {}) {
    const res = await fetch(endpoint, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders, ...headers }
    })
    if (res.status >= 500) {
      throw new Error(res.statusText)
    }
    return res
  }

  static async post(endpoint, data, headers = {}) {
    const res = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders, ...headers }
    })
    if (res.status >= 500) {
      throw new Error(res.statusText)
    }
    return res
  }

  static async postFile(endpoint, data) {
    const res = await fetch(endpoint, {
      method: 'POST',
      body: data
    })
    if (res.status >= 500) {
      throw new Error(res.statusText)
    }
    return res
  }

}

export default HTTP
