import './index.css'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import BodyLeague from '../BodyLeague'
import Footer from '../Footer'
import Header from '../Header'

import HTTP from '../../utils/http'
import VideoCover from '../VideoCover'

function League() {

  const { leagueId } = useParams()
  const [ leagueData, setLeagueData ] = useState()
  const [teams, setTeams] = useState([])

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API}/leagues/${leagueId}`
    HTTP.get(endpoint, {})
      .then(res => res.json())
      .then(setLeagueData)
      .catch((err) => console.error(err.message))
  }, [leagueId])

  useEffect(() => {
    const headers = {
      Authorization: `Beaer test`
    }
    const endpoint = `${process.env.REACT_APP_API}/teams`
    HTTP.get(endpoint, headers)
      .then(res => res.json())
      .then(setTeams)
      .catch((err) => console.error(err.message))
  }, [])

  return (
    <Box className="home-box" sx={{ flexGrow: 1 }}>
      <Header />
      {(leagueData) && (
        <VideoCover
          title={`LIVE ${leagueData.name.toUpperCase()} GAMES 24/7`}
          subTitle="ASIA'S LARGEST SPORTS NETWORK"
        />
      )}
      {(leagueData && teams) && (
        <BodyLeague league={leagueData} teams={teams} />
      )}
      <Footer />
    </Box>
  )

}

export default League
