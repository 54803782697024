import './index.css'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import GamesList from '../GamesList'
import VideoCover from '../VideoCover'
import BetNowButton from '../BetNowButton'

function BodyGamesPage({ gameTime }) {

  return (
    <Box className="Game-box" sx={{ flexGrow: 1 }}>
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={12}>
          <Stack className="Body-main" direction="column" spacing={0}>
             <VideoCover 
              title={`${gameTime === "live" ? "24/7 LIVE GAMES HAPPENING NOW" : "UPCOMING GAMES AND WHEN THEY HAPPEN"}`}
              subTitle="ASIA'S LARGEST SPORTS NETWORK"
             /> 
            
            <BetNowButton /> 

            <GamesList
              type={`${gameTime === "live" ? "live" : "upcoming"}`}
              title={`${gameTime === "live" ? "Live" : "Upcoming"} Games`}
              limit={1000}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )

}

export default BodyGamesPage
