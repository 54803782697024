import './index.css'
import { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import GameTile from '../GameTile'

import HTTP from '../../utils/http'
import { gameTime } from '../../utils'


import 'video.js/dist/video-js.css';
import { Link } from '@mui/material'

function GamesList(props) {
    const {
        title,
        type,
        excludeGame,
        preloadLeagues,
        preloadTeams,
        preloadGames,
        limit,
        moreURL
    } = props;

    const [games, setGames] = useState([])
    const [teams, setTeams] = useState([])
    const [leagues, setLeagues] = useState([])
    const [maxGames, setMaxGames] = useState(limit ? limit : 8)
    
    useEffect(() => {
        if (limit) {
          setMaxGames(limit)
        }
        if (preloadTeams) {
            setTeams(preloadTeams)
        } else {
            const endpoint = `${process.env.REACT_APP_API}/teams`
            HTTP.get(endpoint, {})
                .then(res => res.json())
                .then(setTeams)
                .catch((err) => console.error(err.message))
        }
    }, [preloadTeams, limit])

    useEffect(() => {
        if (preloadLeagues) {
            setLeagues(preloadLeagues)
        } else {
            const headers = {
                Authorization: `Beaer test`
            }
            const endpoint = `${process.env.REACT_APP_API}/leagues`
            HTTP.get(endpoint, headers)
                .then(res => res.json())
                .then(setLeagues)
                .catch((err) => console.error(err.message))
        }
    }, [preloadLeagues])

    useEffect(() => {
      if (preloadGames) {
        setGames(preloadGames)
      } else {
        const endpoint = `${process.env.REACT_APP_API}/games`
        HTTP.get(endpoint, {})
            .then(res => res.json())
            .then(setGames)
            .catch((err) => console.error(err.message))
      }
    }, [preloadGames])

    let liveGames,
        upcomingGames
    let x = 0;
    if (games.length > 0 && leagues.length > 0 && teams.length > 0) {
        liveGames = games.map(game => {
            if (excludeGame?.id === game.id) return false
            
            const gameTimeString = gameTime(game)
            // console.log('gameTimeString', gameTimeString)
            if (gameTimeString === "LIVE") {
                const teamA = teams.find(team => team.id === game.teamIdA)
                const teamB = teams.find(team => team.id === game.teamIdB)
                const league = leagues.find(league => league.id === game.leagueId)

                //console.log('game id', game.id, teams)
                if (x < maxGames) {
                  x++;
                  return (
                      <Grid 
                      key={game.id} 
                      item 
                      xs={12}
                      sm={6}
                      md={12/5}
                      lg={12/5}
                      xl={12/5}
                      >
                      <GameTile
                          game={game}
                          teamA={teamA}
                          teamB={teamB}
                          league={league}
                          type={'live'}
                      />
                      </Grid>
                  )
                }
            }
            return false;
        })

        upcomingGames = games.map(game => {
            if (excludeGame?.id === game.id) return false
            
            const gameTimeString = gameTime(game)

            if (gameTimeString !== 'ENDED' && gameTimeString !== "LIVE") {
                const teamA = teams.find(team => team.id === game.teamIdA)
                const teamB = teams.find(team => team.id === game.teamIdB)
                const league = leagues.find(league => league.id === game.leagueId)

                // console.log('game id', game.id, teams)
                return (
                    <Grid
                    key={game.id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    >
                    <GameTile
                        game={game}
                        teamA={teamA}
                        teamB={teamB}
                        league={league}
                        type={'upcoming'}
                    />
                    </Grid>
                )
            }

            return false
        })
    }

    const ListGameTiles = props => {
        const { type } = props;
        return (
            <Grid 
            container 
            spacing={3}
            >
                { type === 'upcoming' ? upcomingGames : liveGames }
            </Grid>
        )
    }

  return (
    <div className="page-section">
        <Box className="title-bar-container">
            <h1>{title ? title : 'Live Games'}</h1>
        </Box>
        <Box className="Games-box" sx={{ flexGrow: 1 }}>
            <ListGameTiles type={type ? type : 'live'} />
        </Box> 
        { (moreURL) && (
          <Box className="moreLink-container">
              <Link href={moreURL}>View More</Link>
          </Box>
        )}
    </div>
  )
}

export default GamesList
