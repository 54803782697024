import './index.css'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import GamesList from '../GamesList'
import VideoCover from '../VideoCover'
import { gameTime } from '../../utils'
import VideoPlayer from '../VideoPlayer'

function BodyGame({ game, teams }) {
  const [ gameTimeString, setGameTimeString] = useState("")
  let renderTitle = 'Loading'
  if (game && teams.length > 0) {
    const teamA = teams.find(team => team.id === game.teamIdA)
    const teamB = teams.find(team => team.id === game.teamIdB)
    renderTitle = teamA.name + ' VS ' + teamB.name
  }
  
  useEffect(() => {
    console.log(game)
    setGameTimeString(gameTime(game))
  }, [game, gameTimeString])

  return (
    <Box className="Game-box" sx={{ flexGrow: 1 }}>
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={12}>
          <Stack className="Body-main" direction="column" spacing={0}>
            <Box className="Title-box" sx={{ flexGrow: 1 }}>
              <Typography className="Title-label">{ renderTitle }</Typography>
            </Box>
            {
              (gameTimeString === 'LIVE') ?
              <VideoPlayer
                src={`/s/${game.id}/${game.id}.m3u8`}
              />
            :
             <VideoCover 
              title={renderTitle}
              subTitle={`Airs ${gameTime(game, true)}`}
             /> 
            }
           
            <GamesList
              type={`${gameTimeString === "LIVE" ? "live" : "upcoming"}`}
              preloadTeams={teams}
              excludeGame={game}
              title={`More ${gameTimeString === "LIVE" ? "Live" : "Upcoming"} Games`}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )

}

export default BodyGame
