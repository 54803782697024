import './index.css'

function FreeSpins (props) {
    return (
        <div 
            className="freespins-background"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/FREESPINS.jpg)`
            }}
            >
              <a className="linkToBrave" href="https://record.brave158.com/_Qe333NsJyDElu5cdA8BczmNd7ZgqdRLk/1/"></a>
        </div>
    )
}

export default FreeSpins