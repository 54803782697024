import { useEffect, useState } from 'react'
import './index.css'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import HTTP from '../../utils/http'
import GamesList from '../GamesList'
import VideoCover from '../VideoCover';
import { gameTime } from '../../utils';
import BetNowButton from '../BetNowButton';
import VideoPlayer from '../VideoPlayer'

function BodyHome() {
  const [ featuredGame, setFeaturedGame ] = useState()
  const [ featuredChannel, setFeaturedChannel ] = useState()
  const [ isLoading, setIsLoading ] = useState(true)
  const [ streamURL, setStreamURL ] = useState()
  const [ video, setVideo ] = useState()
  const [ testLiveGame, setTestLiveGame ] = useState(false) // Set to true when testing the LiveGame functionality
  const [ timerIsStarted, setTimerIsStarted ] = useState(false)

  console.log(isLoading, !!featuredChannel, !!featuredGame)
  useEffect(() => {
    const fetchAndStartFeaturedChannel = () => {
      const endpoint = `${process.env.REACT_APP_API}/channels/featured`
      HTTP.get(endpoint, {})
          .then(res => res.json())
          .then(channel => {
            setFeaturedChannel(channel)
            setStreamURL(channel.streamURL)
            console.log('featured channel', channel)
            if (video) {
              setIsLoading(false);
              setStreamURL(channel.streamURL)
             }
          })
          .catch((err) => console.error(err.message))
    }

    // This happens second go-around
    // Start Video
    if (isLoading && (featuredGame || featuredChannel) && !video) {

      console.log('startFeaturedGame')
      setIsLoading(false) // <~ This is the end

      console.log('timer started', timerIsStarted)
      if (!timerIsStarted && featuredGame) {
        let x = 1
        const now = (new Date()).getTime()
        const endTime = (new Date(featuredGame.endTime)).getTime()

        console.log({now}, {endTime}, endTime - now)
        const interval = setInterval(() => {
          x++;
          console.log(x)
        }, 1000)
  
        setTimeout(() => {
          console.log("Changing to video")
          clearInterval(interval)
          setIsLoading(true)
        }, (endTime - now))
        setTimerIsStarted(true)
      }
    } 
    
    if (isLoading && video) {
      if (featuredChannel) {
        setFeaturedChannel(null)
      }

      if (featuredGame) {
        setFeaturedGame(null)
      }
    }

    if (isLoading && !featuredChannel && !featuredGame) {
      // Attempt featured game first
      const endpoint = `${process.env.REACT_APP_API}/games/featured`
      HTTP
          .get(endpoint, {})
          .then(res => res.json())
          .then(game => {
            console.log({ game })
            if (gameTime(game) === "LIVE" || game?.isFeatured === true || testLiveGame) {
              setStreamURL(`/s/${game.id}/${game.id}.m3u8`)
              setFeaturedGame(game)
              setTestLiveGame(false)
            } else {
              fetchAndStartFeaturedChannel()
            }
          })
          .catch((err) => console.error(err.message))
    }
  }, [featuredGame, featuredChannel, streamURL, video, isLoading, timerIsStarted])

  return (
    <Box 
      className="Home-box" 
      sx={{ flexGrow: 1 }}>
        <Stack 
          className="Body-main"
          direction="column" 
          spacing={0}
        >
          {
            (!!streamURL) ?
              <VideoPlayer
                src={streamURL}
              /> 
            :
            <VideoCover 
              title="LOADING..."
              subTitle={`Please wait while the game loads.`}
            /> 
          }

          <BetNowButton />
          <GamesList 
            type="live"
            title="Live Games"
            moreURL="/games/live-games"
          /> 
          
          <GamesList
            type="upcoming"
            title="Upcoming Games"
            moreURL="/games/upcoming-games"
          />
        </Stack>
    </Box>
  )

}

export default BodyHome
