import './index.css'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import GamesList from '../GamesList'
import HTTP from '../../utils/http'

function BodyLeague({ league, teams }) {
  const [games, setGames] = useState([])

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API}/games`
    HTTP.get(endpoint, {})
        .then(res => res.json())
        .then(setGames)
        .catch((err) => console.error(err.message))
  }, [])

  let preloadGames = {}
  let LeagueGamesLists = []

  if (games.length > 0) {
    games.forEach(game => {
      if (!preloadGames[game.leagueId]) preloadGames[game.leagueId] = []
      preloadGames[game.leagueId].push(game)
    })
  
      league['games'] = preloadGames[league.id] || []
      if (league['games'].length > 0) {
        return (
          <>
            <GamesList
              key={league.leagueCode}
              type="live"
              preloadTeams={teams}
              title={'Live ' + league.name + ' Games'}
              preloadGames={league.games}
              limit={5}
            />
           <GamesList
              key={league.leagueCode}
              type="upcoming"
              preloadTeams={teams}
              title={`Upcoming ${league.name} Games`}
              preloadGames={league.upcomingGames}
              limit={5}
            /> 
          </>
        )
      }
  }

  return (
    <Box className="Game-box" sx={{ flexGrow: 1 }}>
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={12}>
          <Stack className="Body-main" direction="column" spacing={0}>
            <Box className="Title-box" sx={{ flexGrow: 1 }}>
              <Typography className="Title-label">Live { league.name } Games</Typography>
            </Box>
           
            { LeagueGamesLists }
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )

}

export default BodyLeague
