import './index.css'
import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import Box from '@mui/material/Box'

import Game from '../Game'
import Home from '../Home'
import League from '../League'
import GamesPage from '../GamesPage'
import AboutPage from '../AboutPage'
import { AuthProvider } from '../../utils/auth'
import Category from '../Category'

function App() {
  return (
      <Box className="App" display="flex" justifyContent="center">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/game/:gameId" element={<Game />} />
            <Route path="/league/:leagueId" element={<League />} />
            <Route path="/category/:category" element={<Category />} />
            <Route path="/games/:pageName" element={<GamesPage />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </AuthProvider>
      </Box>
  );
}

export default App
