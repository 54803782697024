import './index.css'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Footer from '../Footer'
import Header from '../Header'

import HTTP from '../../utils/http'
import BodyCategory from '../BodyCategory'
import VideoCover from '../VideoCover'

function Category() {

  const { category } = useParams()
  const [teams, setTeams] = useState([])
  const [categoryData, setCategoryData] = useState()

  useEffect(() => {
    const headers = {
      Authorization: `Beaer test`
    }
    const endpoint = `${process.env.REACT_APP_API}/categories/key/${category}`
    HTTP.get(endpoint, headers)
      .then(res => res.json())
      .then(setCategoryData)
      .catch((err) => console.error(err.message))
  }, [category])

  useEffect(() => {
    const headers = {
      Authorization: `Beaer test`
    }
    const endpoint = `${process.env.REACT_APP_API}/teams`
    HTTP.get(endpoint, headers)
      .then(res => res.json())
      .then(setTeams)
      .catch((err) => console.error(err.message))
  }, [])

  return (
    <Box className="home-box" sx={{ flexGrow: 1 }}>
      <Header />
      {(categoryData) && (
        <VideoCover
          title={`LIVE ${categoryData.name.toUpperCase()} GAMES 24/7`}
          subTitle="ASIA'S LARGEST SPORTS NETWORK"
        />
      )}
      {(categoryData && teams) && (
        <BodyCategory category={categoryData} teams={teams} />
      )}
      <Footer />
    </Box>
  )

}

export default Category 
