// @ts-check
import React, { useState, useEffect, useCallback } from 'react'

const AuthContext = React.createContext({
  token: null,
  handleLogin: null
});

const useAuth = () => React.useContext(AuthContext);

function AuthProvider({ children }) {

  console.log('AuthProvider')

  const accessToken = localStorage.getItem('token')

  const [token, setToken] = useState((accessToken) ? accessToken : null);

  const handleLogin = async (code) => {
    if ( code == process.env.REACT_APP_CODE) {
      localStorage.setItem('token', code)
      setToken(code)
    }
  };

  return (
    <AuthContext.Provider value={{ token, handleLogin }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, useAuth };
