import './index.css'

function BetNowButton() {
  return (
    <div className="betNowButton">
      <a href="https://record.brave158.com/_Qe333NsJyDElu5cdA8BczmNd7ZgqdRLk/1/">BET NOW</a>  
    </div>
  )
}

export default BetNowButton