import './index.css'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import BodyGame from '../BodyGame'
import Footer from '../Footer'
import Header from '../Header'

import HTTP from '../../utils/http'

function Game() {

  const { gameId } = useParams()
  const [game, setGame] = useState()
  const [teams, setTeams] = useState([])

  useEffect(() => {
    const headers = {
      Authorization: `Beaer test`
    }
    const endpoint = `${process.env.REACT_APP_API}/games/${gameId}`
    HTTP.get(endpoint, headers)
      .then(res => res.json())
      .then(setGame)
      .catch((err) => console.error(err.message))
  }, [gameId])

  useEffect(() => {
    const headers = {
      Authorization: `Beaer test`
    }
    const endpoint = `${process.env.REACT_APP_API}/teams`
    HTTP.get(endpoint, headers)
      .then(res => res.json())
      .then(setTeams)
      .catch((err) => console.error(err.message))
  }, [])

  return (
    <Box className="home-box" sx={{ flexGrow: 1 }}>
      <Header />
      {(game && teams) && (
        <BodyGame game={game} teams={teams} />
      )}
      <Footer />
    </Box>
  )

}

export default Game
