import './index.css'
import Typography from '@mui/material/Typography'

import 'video.js/dist/video-js.css';

function VideoCover (props) {
    const { title, subTitle } = props

    return (
        <div 
            className="video-cover"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/player-placeholder.png)`
            }}
            >
            <Typography className='video-cover-title'>
                {title}
            </Typography>
            <Typography className="video-cover-subtitle">
              {subTitle}
            </Typography>
            <div className="video-cover-freetrial">
              <button>START FREE TRIAL<a className="linkToBrave" href="https://record.brave158.com/_Qe333NsJyDElu5cdA8BczmNd7ZgqdRLk/1/"></a>
</button>
              <div className="freetrial-subtext">No hidden fees. Cancel anytime.</div>
            </div>
        </div>
    )
}

export default VideoCover