import './index.css'
import * as React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'

function Header() {

  return (
    <Box className="Header-main">
      <Grid container>
        <Grid item xs={12} lg={2}  className="GridItem-logo">
          <Link href="/"><img alt="logo header" className="Header-logo" src={ process.env.PUBLIC_URL + '/m88-logo.jpg' } /></Link>
        </Grid>
        <Grid item xs={12} lg={10} className="GridItem-menu">
          <Stack className="Header-menu" direction="row" spacing={4}>
            <Link href="/category/football">Football</Link>
            <Link href="/category/nba">NBA</Link>
            <Link href="/category/champions">Champions League</Link>
            <Link href="/category/premier-league">Premier League</Link>
            <Link href="/category/la-liga">La Liga</Link>
            <Link href="/category/uefa">UEFA</Link>
            <Link href="/games/live-games">Live Games</Link>
            <Link href="/games/upcoming-games">Upcoming Games</Link>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )

}

export default Header
