import './index.css'

import Box from '@mui/material/Box'
import BodyHome from '../BodyHome'
import Footer from '../Footer'
import Header from '../Header'
import FreeSpins from '../FreeSpins'
import LiveCasino from '../LiveCasino'

function Home() {

  return (
    <Box className="home-box" sx={{ flexGrow: 1 }}>
      <Header />
      <BodyHome />
      <Footer />
    </Box>
  )

}

export default Home
